import React, { useState } from 'react';

export const styleModalContext = React.createContext();

const Provider = props => {
  const [open, isOpen] = useState(false);
  const [from, setFrom] = useState('cozumel');
  const [priceSnorkel,setPrice] = useState(75);
  //lo que puedes hacer es crear una varible de infanes y pasas una fnncion que le cambie los precios
  return (
    <styleModalContext.Provider value={{
      open,
      openModal: () => isOpen(!open),
      from,
      isFrom: (value) => setFrom(value),
      priceSnorkel,
      setPrice: (value) => setPrice(value),
    }}>
      {props.children}
    </styleModalContext.Provider>
  )
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);